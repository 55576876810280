import { parseISO, format } from 'date-fns'

export function formatDate(dateString): string {
    const parsedDate = parseISO(dateString)
    const formattedDate = format(parsedDate, 'dd.MM.yyyy')
    return formattedDate
}

export function isStillAvailable(endDate: Date, daysToWait?: number): boolean {
    const resultDate = new Date(endDate)
    resultDate.setDate(resultDate.getDate() + (daysToWait || 0))
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    return resultDate >= today
}
