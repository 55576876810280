import { ApiProperty } from '@nestjs/swagger'
import { FormClass, FormField, InputType } from '@stadtlandnetz/forms'
import { IsNotEmpty, IsNumber, IsString, ValidateIf } from 'class-validator'
import { SchoolAffiliationStatus } from '../../transport-request'

export enum SchoolAffiliationValidation {
    NOTE_REQUIRED = 'note_required',
    NOTE_OPTIONAL = 'note_optional'
}

@FormClass()
export class SchoolAffiliationStatusDTO {
    @ApiProperty({ example: 1 })
    @IsNumber({}, { always: true })
    @IsNotEmpty({ always: true })
    id: number

    @ApiProperty({ example: 'comment...' })
    @FormField({
        type: InputType.TEXTAREA,
        oneliner: true,
        rows: 1,
        placeholder: 'account.verification.schoolAffiliation.commentPlaceholder',
        resizable: false,
        mobileOneliner: true,
        compact: true,
        max: 1000,
        customClass: 'schoolAffiliationCommentWrapper',
        validateWithoutTouched: true
    })
    @ValidateIf(() => false, { groups: [SchoolAffiliationValidation.NOTE_OPTIONAL] })
    @IsNotEmpty({ groups: [SchoolAffiliationValidation.NOTE_REQUIRED], message: 'reason has to be given' })
    schoolAffiliationNote: string

    // TODO: Validate enum (currently throws error)
    // @IsEnum(SchoolAffiliationStatus)
    @IsString({ always: true })
    status: SchoolAffiliationStatus

    constructor(init: Partial<SchoolAffiliationStatusDTO>) {
        Object.assign(this, init)
    }
}
